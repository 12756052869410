import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <div className="hero-container">
      {/* <img src="/images/keyart1.jpg" /> */}
      <h1>SuiMonstrX</h1>
      <p>Built by the Community</p>
      <div className="hero-btns">
        <Button
          target="_blank"
          href="https://linktr.ee/SuiMonstrX"
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
        >
          MONSTRXVERSE
        </Button>

        <Button
          target="_blank"
          href="https://raffle.suimonstrx.xyz/"
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
        >
          RAFFLE
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
